import React from 'react'
import Navbar from './Components/Navbar'
import Homepage from './Components/Homepage'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './Components/About';
import Gallary from './Components/Gallary';
import Brands from './Components/Brands';
import Contact from './Components/Contact';
import Branddetails from './Components/Branddetails';
import Unicorn from './Components/Unicorn';
import Hunk from './Components/Hunk';
import Pulsar from './Components/Pulsar';
import Scrolltop from './Components/Scrolltop'
import WhatsAppIcon from './WhatsAppIcon';
import Termsandconsitions from './Components/Termsandconsitions';
import Landing_page from './Landing_page';
function App() {
  return (
    <>
   <BrowserRouter>
   {/* <WhatsAppIcon/> */}
        <Navbar/>

<Scrolltop/>
          <Routes>
            <Route path='/' element={<Homepage/>}/>
           

            <Route path='/about-us'   element={<About/>}/>
            <Route path='/gallery' element={<Gallary/>} />
            <Route path='/brand'   element={<Brands/>} />
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/apache'   element={<Branddetails/>}/>
            <Route path='/unicorn'   element={<Unicorn/>}/>
            <Route path='/hunk'      element={<Hunk/>}/>
            <Route path='/pulsar'     element={<Pulsar/>}/>
            <Route path='/brand/apache'   element={<Branddetails/>}/>
            <Route path='/brand/unicorn'   element={<Unicorn/>}/>
            <Route path='/brand/hunk'      element={<Hunk/>}/>
            <Route path='/brand/pulsar'     element={<Pulsar/>}/>
            <Route path='/TermsAndConditiondsData' element={<Termsandconsitions/>}/>
            <Route path='/PrivacyPolicyData' element={<Termsandconsitions/>}/>
            <Route exact path="landing-page/:campaign/:source" element={<Landing_page />} />

            
          </Routes>
     
        </BrowserRouter>

      
    </>
  )
}

export default App
