import axios from 'axios'
import React, { useEffect, useState } from 'react'

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'bootstrap-4-react/lib/components/layout';
import { Helmet } from 'react-helmet';



function Termsandconsitions() {


    const[data,setData]=useState()
    const [isLoading, setIsLoadings] = useState(true)

    const location = useLocation()

    const pathLocation = location.pathname.slice(1);


    useEffect(()=>{

        axios.get('https://admin.autorentmotorcycle.com/api/get'+pathLocation)
        .then(response =>{setData(response.data.data); setIsLoadings(false)})

    },[location])



    console.log(pathLocation)
  return (
    <div>

      <Helmet>
      <title>Autorent Motorcycle - Terms and Conditionds</title>
     <meta name="description" content="Terms and Conditionds" />
     <meta name="keywords" content="Terms and Conditionds"></meta>
      </Helmet>
        
    { 
    isLoading ?
    <Container>
      <Row style={{ height: '650px', padding: '0px' }}>
        <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
          {/* <Spinner style={{color:'red'}}/>  */}
          <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
        </Col>
      </Row>
    </Container>:
    
        <Container className='mb-5 mt-5'>
            <Row>
                <Col lg={11} style={{marginLeft:'1%'}}>
    <h3>{data &&data.title}</h3>
      <p>{data &&  parse(data.description)}</p>
      </Col>
            </Row>
      </Container>
      
      }
    </div>
  )
}

export default Termsandconsitions
