import Carousel from 'react-bootstrap/Carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';
import { BsArrowRightShort } from 'react-icons/bs'
import Footer from './Footer';
import { Link, json } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import parse from 'html-react-parser';
import { Card } from 'bootstrap-4-react/lib/components';
import { createContext } from 'react';
import $ from 'jquery';
import BookNow from './BookNow';
import { Helmet } from 'react-helmet';


export const store = createContext()
function CarouselFadeExample() {

  const [sharedata, setSharedata] = useState()
  const [banner, setBanner] = useState()
  const [brands, setBrands] = useState()
  const [company, setCompany] = useState()
  const [metadata, setMetaData] = useState()


  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getBanners')
      .then(response => setBanner(response.data.data))
    axios.get('https://admin.autorentmotorcycle.com/api/getBrands')
      .then(response => {
        setBrands(response.data.data);
      }
      )
    axios.get('https://admin.autorentmotorcycle.com/api/getCompanyProfile')
      .then(response => setCompany(response.data.data))
  }, [0])


  const handleModel = (brands) => {
    setSharedata(brands);
  };


  $(document).ready(function () {
    $('.book-now').click(function () {
      $('.card').removeClass('active'); // Remove 'active' class from all cards
      $(this).addClass('active'); // Add 'active' class to the clicked card
    });
  });



  return (
    <>

      <Helmet>
        <title>Autorent Motorcycle - Home</title>
        <meta name="description" content="Home" />
        <meta name="keywords" content="Home"></meta>
      </Helmet>

      <Carousel indicators={false}>
        {banner && banner.map((item, index) => {
          return (
            <Carousel.Item key={index}  >
              <img className="d-block w-100" src={item.large_image_path + item.brand_image} alt="Slide" />
              <Carousel.Caption>
                <h3 className='banner-heading-1'>{item.brand_fist_heading}</h3>
                <h3 className='banner-heading-2'>{item.brand_fist_sub_heading}</h3>
                <p >{item.brand_second_heading}</p>
                <p>{item.brand_second_sub_heading}</p>
                <p className='crousel-left-icon-p' data-toggle="modal" data-target="#exampleModal-1"  ><span>BOOK NOW <BsArrowRightShort className='crousel-left-icon' /></span>  </p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>

      <Container>
        <Row style={{ display: 'flex', justifyContent: 'center' }} className='brands'>
          <Col lg={12} style={{ padding: '20px' }}>
            <Row>
              {brands &&
                brands.map((item, index) => {
                  const isLastItem = index === brands.length - 1;
                  const itemStyle = isLastItem ? { borderRight: 'none' } : { borderRight: '1px solid black' };
                  return (
                    <Col>
                      <Link to={item.slug} key={index}>
                        <div className='item' style={itemStyle}>
                          <p className='owl-theme-p'>{item.brand_title}</p>
                          <img
                            src={item.large_image_path + '/' + item.brand_model_image}
                            alt={item.brand_title}
                            width={'100%'}
                            height={'auto'}
                          />

                        </div>
                      </Link>
                    </Col>
                  );
                })}
            </Row>

          </Col>

        </Row>
      </Container>

      <Container fluid >

        <Row className='mt-5'>
          <Col lg={6} xs={6} style={{ display: 'flex', justifyContent: 'end' }}> <h1 className='auto-rent-heading-1'>Autorent</h1></Col>
          <Col lg={6} xs={6} style={{ display: 'flex', justifyContent: 'start' }}><h1 className='auto-rent-heading-2'>Advantage</h1></Col>
        </Row>
        <div className='text-center heading-div'>
          <h6 className='auto-rent-heading-3'>We get you where you want to be, fast and safe</h6>
        </div>
        <Row className='auto-rent-row' >
          <Col className='compant-profile' lg={10} md={10}>
            <div className='mt-5'>

              <Row>
                <Col lg={6} md={6} sm={9} xs={12} style={{ padding: '10px' }}>
                  <div>
                    <h2 style={{ fontWeight: '700' }} className='auto-rent-font'>Autorent Motorcycle Rental</h2>
                    <p className='p-text'>Autorent Motorcycle Rental LLC is one of the
                      youngest leading motorcycle rental and leasing companies with 500+ successful
                      deliveres and happy customers. With a growing presence in UAE and plans to expand
                      further in GCC region, Autorent Motorcycle Rental has been helping its customers
                      to get on to the road faster with truly hassle-free motorcycle rentals and leasing
                      services</p>
                  </div>

                </Col>
                <Col lg={6} md={6} sm={3} xs={12} style={{ padding: '20px', paddingTop: '0px', marginTop: '-4%' }}>
                  <img src="/Asest/bike/3699862_New.jpg" width={'100%'} />
                </Col>
              </Row>
            </div>




          </Col>


          <Col lg={10} className=' card-col'>


            <Row>
              <Col lg={4} md={4} style={{ paddingTop: '10px' }}>
                <Card className="home-cards">
                  <div className='text-center mb-3' style={{ minHeight: '60px' }}>
                    <img src='/Asest/Homepage/Motorcycle_ICONS-02.png' width={'50px'} />
                  </div>
                  <div style={{ minHeight: '100px' }}>
                    <h5 className='text-center' >On time, every time</h5>
                    <p className='text-center'>All our motorcycle are brand new,safe,durable and ready to ride for the roads</p>
                  </div>

                </Card>
              </Col>

              <Col lg={4} md={4} style={{ paddingTop: '10px' }}>
                <Card className="home-cards">
                  <div className='text-center mb-3' style={{ minHeight: '60px' }}>
                    <img src='/Asest/Homepage/bike.png' width={'68px'} style={{ marginTop: '-5%' }} />

                  </div>
                  <div style={{ minHeight: '100px' }}>
                    <h5 className='text-center' style={{ textTransform: 'capitalize' }}>Hassle-free service</h5>
                    <p className='text-center'>Documentations are taken care by our Autorent team which makes the experiences extremely fast and efficient

                    </p>        </div>

                </Card>
              </Col>
              <Col lg={4} md={4} style={{ paddingTop: '10px' }}>
                <Card className="home-cards" >
                  <div className='text-center mb-3' style={{ minHeight: '60px' }}>
                    <img src='/Asest/Homepage/Motorcycle_ICONS-03.png' width={'50px'} />
                  </div>
                  <div style={{ minHeight: '100px' }}>
                    <h5 className='text-center'>Flexi Fares</h5>
                    <p className='text-center'>We ensure that our customers are happy by charging them
                      reasonably for the experience that we provide

                    </p>        </div>

                </Card>
              </Col>
            </Row>
          </Col>



        </Row>

      </Container>


      <Container fluid >
        <Row style={{ display: 'flex', justifyContent: 'center' }}>


          {
            brands && brands.map((item, index) => {

              const backgroundcolor = item.background_color
              return (


                <Row style={{ display: 'flex', justifyContent: 'center' }}>

                  {index % 2 === 0 ?


                    <Col lg={10} md={12} xs={12} className='mt-5 '>
                      <Row className='bike-details-col'>

                        <Col lg={6} md={7} xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <Row>

                              <Col lg={9} md={9} xs={9} style={{ backgroundColor: backgroundcolor, display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '.9', color: 'white' }}>
                                <div className='bike-info-tages'  >
                                  <span style={{ fontWeight: '700', paddingRight: '10PX' }} className='heaing-one'>{item.brand_title}</span>
                                  <span style={{ fontWeight: '700' }}>{item.brand_model}</span>
                                  <p>{parse(item.description)}</p>
                                  <span data-toggle="modal" data-target="#exampleModal-1" onClick={() => handleModel(item)} style={{ cursor: 'pointer' }} >BOOK NOW <BsArrowRightShort className='crousel-left-icon' size={'30px'} /></span>
                                </div>
                              </Col>


                              <Col lg={3} md={3} xs={3} className='triangle-left-5' style={{ backgroundColor: `${backgroundcolor}`, opacity: '.9' }}>

                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col lg={6} md={5} xs={5}>
                          <img src={item.large_image_path + '/' + item.brand_image} width={'100%'} />
                        </Col>
                      </Row>



                    </Col>


                    :

                    <Col lg={10} className='mt-5'>
                      <Row className='bike-details-col'>


                        <Col lg={6} xs={5} >
                          <img src={item.large_image_path + '/' + item.brand_image} width={'100%'} />

                        </Col>


                        <Col lg={6} xs={7} style={{ display: 'flex', alignItems: 'center' }}>

                          <div>

                            <Row>

                              <Col lg={3} xs={3} className='triangle-right-5' style={{ backgroundColor: ` ${backgroundcolor}`, opacity: '.9' }} >


                              </Col>


                              <Col lg={9} xs={9} style={{ backgroundColor: backgroundcolor, display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '.9', color: 'black' }}>

                                <div className='bike-info-tages' >

                                  <span style={{ fontWeight: '700', paddingRight: '10PX' }} className='heaing-one'>{item.brand_title}</span>
                                  <span style={{ fontWeight: '700' }}>{item.brand_model}</span>
                                  <p>{parse(item.description)}</p>
                                  <span data-toggle="modal" data-target="#exampleModal-1" onClick={() => handleModel(item)} style={{ cursor: 'pointer' }}  >BOOK NOW <BsArrowRightShort className='crousel-left-icon' size={'30px'} /></span>
                                </div>
                              </Col>


                            </Row>
                          </div>
                        </Col>




                      </Row>


                    </Col>


                  }
                </Row>)
            })
          }

        </Row>



      </Container>
      <store.Provider value={[sharedata, setSharedata]}>
        <BookNow />
      </store.Provider>

      <Container fluid className='home-gallary mb-5 mt-5'>

        <h1 className='text-center g-heading mb-5'>GALLERY</h1>
        <Row >
          <Col lg={12} xs={12} md={12} style={{ padding: '0px' }}>
            <img src='/Asest/Homepage/gallary.jpg' width={'100%'} className='gallray-long-img' />
          </Col>
        </Row>




      </Container>
      <Footer />
    </>

  );
}

export default CarouselFadeExample;


















