import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container ,Row,Col, Carousel,Form, Button} from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Footer from './Components/Footer';


function Landing_page() {
  const { campaign, source } = useParams();
  const[banner,setBanner]=useState()
 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone:''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({...prevState, [name]: value }));
  };
  useEffect(()=>{
    axios.get('https://admin.autorentmotorcycle.com/api/getLeadImages')
    .then(res =>{setBanner(res.data.data.lead_iamges
      )})

  },[])

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   axios.post('https://admin.autorentmotorcycle.com/api/storeLeads', {
  //     ...formData,
  //     campaign: campaign,
  //     source: source
  //   })
  //   .then(response => {
  //     console.log(response.data);
  //     // Handle success or further actions if needed
  //   })
  //   .catch(error => {
  //     console.error('Error submitting form:', error);
  //     // Handle error
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://admin.autorentmotorcycle.com/api/storeLeads', {
      ...formData,
      campaign: campaign,
      source: source
    })
    .then(response => {
      console.log(response.data);
      toast.success("Form submitted successfully!");
      // Handle success or further actions if needed
    })
    .catch(error => {
    if (error.response && error.response.data && error.response.data.data) {
      const { email, name, phone } = error.response.data.data;
      if (email) {
        toast.error(` ${email}`);
      }
      if (name) {
        toast.error(`${name}`);
      }
      if (phone) {
        toast.error(`${phone}`);
      }
    } else {
      toast.error('Error submitting form');
    }
    // Handle other errors
  

    });
  };
  
  return (
    <>
    <ToastContainer />
      <Container>
        <Row className='mt-5' style={{display:'flex',justifyContent:'center'}}>
          
          <Col lg={9}>
          <h3 className=' mb-5 text-center'>  Get in touch with us today!</h3> 

            
            <Carousel >
              {
                banner && 
                banner.map((item)=>{
                  return(
                    <Carousel.Item>
                    <img src={item.image_path+item.image}  width={'100%'}/>
                  </Carousel.Item>

                  )
                })
               
             

              }
            
            </Carousel>

           
          </Col>
          <Col lg={4} >
        <h3 className='mt-5 mb-5 text-center'>  Submit Your Details</h3> 

<form onSubmit={handleSubmit}  >
  <div className="mb-3">
    <label htmlFor="name" className="form-label">Name</label>
    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
  </div>
  <div className="mb-3">
    <label htmlFor="email" className="form-label">Email</label>
    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
  </div>
  <div className="mb-3">
    <label htmlFor="message" className="form-label">Phone</label>
    <input type='number' className="form-control" id="message" name="phone" value={formData.phone} onChange={handleChange} />
  </div>
  <div className="mb-3">
    <label htmlFor="message" className="form-label">Message</label>
    <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
  </div>
 
  <div style={{width:'100%'}}>
  <button style={{width:'100%'}} type="submit"  className="btn btn-primary mb-5">Submit</button>
  </div>
</form>
          </Col>
        </Row>
      </Container>   
      <Footer/>   
    </>
  )
}

export default Landing_page;
